body {
  background: #000000;
  scroll-behavior: smooth !important;
}
.vjs-big-play-button {
  background-color: black !important;
  border: none !important;
  font-size: 7em !important;
}

@media screen and (max-width: 765px) {
  .vjs-big-play-button {
    font-size: 3em !important;
  }
}

.video-js {
  z-index: 1 !important;
}
.vjs-control-bar {
  display: none !important;
}
